@font-face {
    font-family: "CormorantLightItalic";
    src: local("CormorantLightItalic"),
        url("./assets/fonts/cormorant.light-italic.otf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "CormorantUnicase";
    src: local("CormorantUnicase"),
        url("./assets/fonts/cormorant.unicase-semi.otf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "CormorantRegularItalic";
    src: local("CormorantRegularItalic"),
        url("./assets/fonts/cormorant.regular-italic.otf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "CormorantLightItalic";
    src: local("CormorantLightItalic"),
        url("./assets/fonts/cormorant.light-italic.otf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "CormorantRegular";
    src: local("CormorantRegular"),
        url("./assets/fonts/cormorant.regular.otf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "CormorantUprightMedium";
    src: local("CormorantUprightMedium"),
        url("./assets/fonts/cormorant.upright-medium.otf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "CormorantSCBold";
    src: local("CormorantSCBold"),
        url("./assets/fonts/cormorant.sc-bold.otf") format("truetype");
    font-weight: 900;
}
@font-face {
    font-family: "CormorantSC";
    src: local("CormorantSC"),
        url("./assets/fonts/cormorant.sc.otf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "CormorantMedium";
    src: local("CormorantMedium"),
        url("./assets/fonts/cormorant.medium.otf") format("truetype");
    font-weight: 400;
}