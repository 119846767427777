.mobile-nav{
    .navLinkText {
      color: #8a701a;
      text-decoration: none;
      font-size: 22px;
      text-align: center;
      width: 100%;
    }
    
    .active {
      color: #b08e29;
      text-decoration: none;
      font-size: 25px;
      text-align: center;
      width: 100%;
    
    }
  }
  .main-nav-list{
    // background-color: #61dafb;
    display: flex;
    justify-content: flex-end;
    // width: 80%;
    font-weight: 500;
    margin: 0 20px 0 20px;
    @media (max-width: 1300px) {
        margin: 0 10px 0 10px;
    }
    // text-transform: uppercase;
    .navLinkText {
      // color: white;
      color: #8a701a;
      text-decoration: none;
      font-size: 28px;
      text-align: center;
      width: 100%;

    }
    
    .active {
      color: #b08e29;
      text-decoration: none;
      font-size: 30px;
      text-align: center;
      width: 100%;
    
    }
  }