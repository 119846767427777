@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

i {
    color: #8a701a;
    cursor: pointer;
    font-size: 14px !important;
}


i[data-tooltip]::after {
    content: attr(data-tooltip);
    width: max-content;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    padding: 8px 8px;
    border-radius: 4px;
    color: #8a701a;
    transform: scale(0);
    transition: 0.2s ease-in-out;
    font-size: 19px;
}

i[data-tooltip]:hover::after {
    transform: scale(1);
    bottom: -100%;
    // left: 50px;
}

.popover {
    position: relative;
    display: inline-block;
    color: #b08e29;
    font-family: CormorantRegular;
    font-size: 28px;
    text-align: left;
    // overflow: auto;
    pointer-events: auto;
    //   margin: 22px 2px;
    // overflow: auto;
    div{
        color: #b08e29;
        font-family: CormorantRegular;
        font-size: 28px;
        @media (max-width: 600px) {
            font-size: 22px;
          }
    }
}
.info-text{
    color: #5e4c0f;
    font-size: 22px;
    text-align: left;
    padding: 8px;
    font-family: CormorantRegular;
    // background-color: #8a701a;
    background-color: #e0bba8;
    span{
        font-family: CormorantRegularItalic;
    }
}
.reference{
    // width: 20px;
    // height: 20px;
    // background-color: #8a701a;
    // border-radius: 50%;
    font-weight: 600;
    color: #f6d05c;
    font-family: CormorantSCBold;
    font-size: 28px;
    // text-align: left;
    // margin: 10px 0 16px 0;
}
.reference:hover{
    color: white;
}