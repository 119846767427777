p {
    font-weight: 300;
    // color: #b08e29;
    font-family: CormorantRegular;
    font-size: 19px;
    span {
      // font-style: italic;
      font-family: CormorantRegularItalic;
      font-size: 19px;
    }
  }