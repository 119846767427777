@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');



* {
  // font-family: 'Kanit', sans-serif;
  font-family: 'Cormorant', serif;

}

.App {
  text-align: center;
  background-color: #552B13;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  // justify-content: center;
  align-items: center;
  color: white;
  margin-top: 80px;
  width: 100%;
  padding-bottom: 100px;
}

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #7D6514; */
  background-color: #552B13;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-garland {
  background-image: url('./assets/resources/carv-h.svg');
  height: 30px;
  width: 100%;
  // background-position: center center;
  background-size: contain;

  @media (max-width: 787px) {
    background-size: cover;
  }
}

.book-card-container {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

// @media (max-width: 787px) {
//   .book-card-container{
//     display: flex;

//     align-items: center;
//     justify-content: center;

//   }
// }

.bookmark {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;



  h1 {
    @media (min-width: 900px) {
      font-size: 3em;
      font-weight: 400;
    }
    font-size: 2em;
    margin: 0 16px 0 16px;
    font-weight: 400;
    color: #b08e29;
    font-family: 'CormorantSC';

    span {
      font-family: 'CormorantLightItalic';
      font-weight: 200;
      font-size: 1.1em;
    }
  }

  h1:after,
  h1:before {
    content: " ";
    display: block;
    border-bottom: 3px solid #877439;
    // border-top: 1px solid #ccc;
    height: 5px;
    margin: 10px 0 15px 0;
    padding: 5px;
    background-color: transparent;
  }

  #book-mark-container {
    margin-top: 30px;
    img {
      height: 300px;
      width: 405px;

      @media (max-width: 600px) {
        height: 200px;
        width: 268px;
      }

      @media (max-width: 300px) {
        height: 100px;
        width: 134px;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }
    .container-text {
      display: flex;
      width: 50%;

      @media (max-width: 600px) {
        width: 80%;
      }

      .bqstart {
        margin-top: -20px;
        float: left;
        font-size: 500%;
        color: #745f20;
        font-family: CormorantMedium;
        opacity: 0.6;
        z-index: 1;
        @media (max-width: 1100px) {
          margin-top: -15px;
          margin-right: -1px;
        }
        @media (max-width: 900px) {
          margin-top: -7px;
          margin-right: -8px;
        }
        @media (max-width: 700px) {
          margin-top: -7px;
          margin-left: -20px;
        }
        @media (max-width: 500px) {
          margin-top: -7px;
          margin-right: 6px;
        }
        // line-height: 50%;
        // @media (max-width: 600px) {
        //   margin-top: 20px;
        // margin-bottom: -50px;
        // }
      }

      .quoteText {
        font-size: 30px;
        color: #877439;
        text-indent: -5px;

        .bqstart {
          font-size: 5em;
          margin: 0;
          padding: 0;
          top: 10;
          position: absolute;
          color: #877439;
          font-family: CormorantMedium;
          @media (max-width: 700px) {
            // top: -90px;
            top: 30px;
          }
        }

        span {
          font-style: italic;
          font-size: 30px;

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }

        @media (max-width: 600px) {
          font-size: 20px;
          // text-align: left;
        }
      }
    }
  }
}

.article {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%;
  }

  h1 {
    font-size: 2em;
    font-weight: 400;
    color: #9c780c;
    font-family: CormorantRegularItalic;
    border-bottom: 3px solid #9c791979;
    padding-bottom: 4px;

    @media (max-width: 600px) {
      font-size: 2em;
      // width: 90%;
    }
  }

  img {
    height: 400px;
    border-radius: 5px;
    border: 4px solid #b08e29;

    @media (max-width: 600px) {
      height: 200px;
      margin-top: 10px;
      // width: 268px;
    }

    // @media (max-width: 300px) {
    //   height: 100px;
    //   // width: 134px;
    // }
  }

  p {
    font-weight: 300;
    color: #b08e29;
    font-family: CormorantRegular;
    font-size: 28px;
    text-align: left;
    margin: 10px 0 16px 0;

    .sc {
      font-family: CormorantSC;
    }
    sup{
      font-size: 15px;
    }
    //  text-indent: 50px;
    .dropcap {
      color: #b08e29;
      // float: left;
      font-family: CormorantRegular;
      font-size: 65px;
      line-height: 45%;
      // padding-top: 4px;
      padding-right: 3px;
      padding-left: 3px;

      // margin-left: 30px;
      // text-indent: 40px;

      @media (max-width: 600px) {
        font-size: 40px;
        line-height: 10px;
        padding-top: 4px;
        padding-right: 1px;
        padding-left: 3px;
      }
    }

    span {
      font-family: CormorantRegularItalic;
      font-size: 28px;

      @media (max-width: 600px) {
        font-size: 22px!important;
      }
    }


    .upright {
      font-family: CormorantUprightMedium;
      font-size: 28px;
    }

    @media (max-width: 600px) {
      font-size: 22px;

      @media (max-width: 600px) {
        font-size: 22px;
      }
    }
  }

  .quotation {
    text-align: end;
    font-size: 21px;
    width: 100%;

    @media (max-width: 600px) {
      font-size: 18px;
    }

    span {
      font-style: italic;
    }

    div {
      text-align: end;
      font-size: 18px;
      color: #8a701a;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
}

.book-cover {
  height: 400px;
  width: 300px;
  margin-top: 16px;
  opacity: 1;
  border: 2px solid #b08e29;

  @media (max-width: 400px) {
    height: 350px;
    width: 250px;
  }
}

.end-mark {
  hr {
    border: none;
  }

  hr:after {
    content: "\00a7";
    display: block;
    font-size: 1.5em;
    margin: 1em auto;
    text-align: center;
    color: #b08e29;
  }
}
.foot-notes-section {
  border-top: 1px solid #b08e29;
  padding-top: 16px;

  li {
    font-weight: 300;
    color: #b08e29;
    font-family: CormorantRegular;
    font-size: 20px;
    text-align: left;
    margin-bottom: 8px;

    span {
      font-family: 'CormorantLightItalic';
      font-weight: 200;
      font-size: 1.1em;
    }
  }
}