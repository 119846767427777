.card {
    // padding-top: 100px;
    // padding: 25px 15px 25px 15px;
    transform-style: preserve-3d;
    // background-image: url("../../assets//images//bookCovers//cover_final_2022-07-09_Abridged_Version.jpg");
    // background-image: url("https://st.depositphotos.com/2627989/4694/i/600/depositphotos_46941945-stock-photo-spring-forrest-sunset.jpg");
    // background-position: center center;
    // background-size: cover;
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    height: 200px;
    
   

}

.content-box {
    min-height: 340px;
    width: 300px;    
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.711);
    border-radius: 10px;
    box-shadow: #bb9312 0px 30px 50px -25px;
    transition: all .5s ease-in-out;
    padding: 10px 15px 25px 15px;
    transform-style: preserve-3d;
    &:hover {
        background-position: 80% 20%;
        background-color: rgba(0, 0, 0, 0.511);
        // background-image: url("https://st.depositphotos.com/2627989/4694/i/600/depositphotos_46941945-stock-photo-spring-forrest-sunset.jpg");
        // transform: rotate3d(0.5, 1, 0, 30deg);
    }
    @media (max-width: 500px) {
        width: 100%;
    }

    .card-title {
        color: #dad7d0;
        // color: #bb9312;
        font-size: 23px;
        font-weight: 900;
        font-family: CormorantSC;
        transition: all 0.5s ease-in-out;
        transform: translate3d(0px, 0px, 20px);
        min-height: 30px;
        // padding: 0 20px 0 20px;
    }

    .card-content {
        font-size: 22px;
        color: white;
        transition: all 0.5s ease-in-out;
        transform: translate3d(0px, 0px, 20px);
        text-align: center;
        margin-bottom: 40px;;
    }

    div{
        // margin-top: 80px;
        bottom: 10px;
        left: 0;
        position: absolute;
        width: 100%;
        align-self: center;
        div{
            // padding-top: 45px;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
          
            .see-more {
              
                display: block;
                font-weight: 900;
                font-size: 14px;
                text-transform: uppercase;
                // color: rgb(255, 255, 255);
                color: #bb9312;
                transition: all 0.5s ease-in-out;
                transform: translate3d(0px, 0px, 20px);
                
                &:hover {
                    color: white;
                }
            }
           }
        }
    }
.content-box.size{
    min-height: 240px;;
}